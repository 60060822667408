import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import { Styles } from "./departmentLIstStyle";

export default class DepartmentList extends Component {
  render() {
    const Datas = {
      dataList: [
        {
          id: "1",
          deptImg: "departments/dept-6.jpg",
          deptLink: "/civil-about",
          deptTitle: "Civil Engineering",
          deptHod: "Dr.B.S.PUTTEGOWDA",
          deptEmail: "hodcivil@bit-bangalore.edu.in",
          deptYear: "1979",
          contactlink: "/civil-contact",
        },
        {
          id: "2",
          deptImg: "departments/dept-5.jpg",
          deptLink: "/mech-about",
          deptTitle: "Mechanical Engineering",
          deptHod: "Dr.T.V.SREERAMA REDDY",
          deptEmail: "hodmech@bit-bangalore.edu.in",
          deptYear: "1979",
          contactlink: "/mech-contact",
        },
        {
          id: "3",
          deptImg: "departments/dept-7.jpg",
          deptLink: "/eee-about",
          deptTitle: "Electrical and Electronics Engineering",
          deptHod: "DR. SWARNALATHA SRINIVAS",
          deptEmail: "hodeee@bit-bangalore.edu.in",
          deptYear: "1979",
          contactlink: "/eee-contact",
        },
        {
          id: "4",
          deptImg: "departments/dept-4.jpg",
          deptLink: "/ece-about",
          deptTitle: "Electronics and Communication Engineering",
          deptHod: "Dr.HEMANTH KUMAR A.R",
          deptEmail: "hodece@bit-bangalore.edu.in",
          deptYear: "1979",
          contactlink: "/ece-contact",
        },
        {
          id: "5",
          deptImg: "departments/dept-1.jpg",
          deptLink: "/cse-about",
          deptTitle: "Computer Science and Engineering",
          deptEmail: "hodcse@bit-bangalore.edu.in",
          deptHod: "Dr.J.GIRIJA",
          deptYear: "1981",
          contactlink: "/ce-contact",
        },
        {
          id: "6",
          deptImg: "departments/dept-9.jpg",
          deptLink: "/eie-about",
          deptTitle: "Electronics and Instrumentation Engineering",
          deptEmail: "hodeie@bit-bangalore.edu.in",
          deptHod: "Dr. Aswathappa P",
          deptYear: "1982",
          contactlink: "/eie-contact",
        },
        {
          id: "7",
          deptImg: "departments/dept-10.jpg",
          deptLink: "/iem-about",
          deptTitle: "Industrial Engineering and Management",
          deptEmail: "hodiem@bit-bangalore.edu.in",
          deptHod: "Prof. V.S.GIRI",
          deptYear: "1986",
          contactlink: "/iem-contact",
        },
        {
          id: "8",
          deptImg: "departments/dept-8.jpg",
          deptLink: "/ete-about",
          deptTitle: "Electronics and Telecommunication Engineering",
          deptEmail: "hodete@bit-bangalore.edu.in",
          deptHod: "Dr.M.RAJESWARI",
          deptYear: "1992",
          contactlink: "/ete-contact",
        },
        {
          id: "9",
          deptImg: "departments/dept-2.jpg",
          deptLink: "/ise-about",
          deptTitle: "Information Science and Engineering",
          deptEmail: "hodise@bit-bangalore.edu.in",
          deptHod: "Dr. Asha.T",
          deptYear: "2000",
          contactlink: "/ise-contact",
        },
        {
          id: "10",
          deptImg: "departments/dept-3.jpg",
          deptLink: "/ai-about",
          deptTitle: "Artificial Intelligence and Machine Learning",
          deptHod: "Dr.JYOTHI D.G",
          deptEmail: "hodaiml@bit-bangalore.edu.in",
          deptYear: "2020",
          contactlink: "/ai-contact",
        },
        {
          id: "11",
          deptImg: "departments/dept-16.jpg",
          deptLink: "/cyber-about",
          deptTitle: "CSE (IOT & Cyber Security, Blockchain Technology)",
          deptHod: "Dr. Shivakumar B R",
          deptEmail: "shivakumarbr@bit-bangalore.edu.in",
          deptYear: "2022",
          contactlink: "/cyber-about",
        },
        {
          id: "12",
          deptImg: "departments/dept-17.jpg",
          deptLink: "/datascience-about",
          deptTitle: "Computer Science & Engineering (Data Science)",
          deptHod: "Dr. B. N. Shankar Gowda",
          deptEmail: "bnsgowda@bit-bangalore.edu.in",
          deptYear: "2022",
          contactlink: "/datascience-about",
        },
        {
          id: "13",
          deptImg: "departments/dept-18.jpg",
          deptLink: "/robotics-about",
          deptTitle: "Robotics & Artificial Intelligence",
          deptHod: "Dr. N Satish",
          deptEmail: "satishn@bit-bangalore.edu.in",
          deptYear: "2022",
          contactlink: "/robotics-about",
        },
        {
          id: "14",
          deptImg: "departments/dept-19.jpg",
          deptLink: "/vlsi-about",
          deptTitle: "Electronic Engineering (VLSI Design & Technology)",
          deptHod: "Dr. Vijaya Prakash A M",
          deptEmail: "am_vprakash@bit-bangalore.edu.in",
          deptYear: "2022",
          contactlink: "/vlsi-about",
        },
        {
          id: "15",
          deptImg: "departments/dept-11.jpg",
          deptLink: "/mba-about",
          deptTitle: "Master of Business Administration (MBA)",
          deptHod: "Dr. K.V.DEEPAK",
          deptEmail: "drkvdeepak@bit-bangalore.edu.in",
          deptYear: "2006",
          contactlink: "/mba-contact",
        },
        {
          id: "16",
          deptImg: "departments/dept-12.jpg",
          deptLink: "/mca-about",
          deptTitle: "Master of Computer Applications (MCA)",
          deptHod: "Dr.T.VIJAY KUMAR",
          deptEmail: "hodmca@bit-bangalore.edu.in",
          deptYear: "1997",
          contactlink: "/mca-contact",
        },
        {
          id: "17",
          deptImg: "departments/dept-13.JPG",
          deptLink: "/phy-about",
          deptTitle: "Physics",
          deptHod: "Dr.D.NAGARAJA",
          deptEmail: "hodphysics@bit-bangalore.edu.in",
          deptYear: "1979",
          contactlink: "/phy-contact",
        },
        {
          id: "18",
          deptImg: "departments/dept-14.jpg",
          deptLink: "/chem-about",
          deptTitle: "Chemistry",
          deptHod: "Dr. Chandrashekhara B M",
          deptEmail: "hodchemistry@bit-bangalore.edu.in",
          deptYear: "1979",
          contactlink: "/chem-contact",
        },
        {
          id: "19",
          deptImg: "departments/dept-15.jpg",
          deptLink: "/math-about",
          deptTitle: "Mathematics",
          deptHod: "Prof. Prashantha Kumar",
          deptEmail: "hodmathematics@bit-bangalore.edu.in",
          deptYear: "1979",
          contactlink: "/math-contact",
        },
      ],
    };

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Departments" image="banners/department-list.jpg" />

        {/* Content */}
        <Styles>
          <section className="home-blog-area">
            <Container>
              <Row>
                {Datas.dataList.map((data, i) => (
                  <Col md="6" key={i}>
                    <div className="blog-post">
                      <Row>
                        <Col lg="6" md="12">
                          <div className="blog-img">
                            <Link to={process.env.PUBLIC_URL + data.deptLink}>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/${data.deptImg}`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </Link>
                          </div>
                        </Col>
                        <Col lg="6" md="12">
                          <div className="blog-content">
                            <div className="content-box">
                              <div className="top-content d-flex">
                                <div className="blog-title">
                                  <h6>
                                    <Link
                                      to={
                                        process.env.PUBLIC_URL + data.deptLink
                                      }
                                    >
                                      {data.deptTitle}
                                    </Link>
                                  </h6>
                                </div>
                              </div>
                              <div className="blog-desk">
                                Started In : {data.deptYear}
                                <br />
                                HOD : {data.deptHod}
                                <br />
                                <hr />
                                <Link
                                  to={process.env.PUBLIC_URL + data.contactlink}
                                  style={{ color: "black" }}
                                >
                                  Contact : {data.deptEmail}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </section>
        </Styles>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
