import React, { Component } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import AdmissionSidebar from "./components/AdmissionSidebar";

export default class PHD extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Admission" image="banners/banner-admission.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Ph.D Programs</h4>
                    </div>
                    <div className="ttm-service-description">
                      <table class="table">
                        <thead
                          style={{ backgroundColor: "#98002e", color: "white" }}
                        >
                          <tr>
                            <th style={{ width: "4%" }}>Sl No</th>
                            <th style={{ width: "23%" }}>
                              Departments offering Ph.D Programs
                            </th>
                            <th style={{ width: "20%" }}>Guide Name</th>
                            <th style={{ width: "37%" }}>Area</th>
                            <th style={{ width: "8%" }}>Ph.D Pursuing</th>
                            <th style={{ width: "7%" }}>No of vacancies</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>1</th>
                            <th>CSE </th>
                            <td>DR. Asha T</td>
                            <td>
                              Data Mining, Soft computing, Machine learning
                            </td>
                            <td>03</td>
                            <td>05</td>
                          </tr>
                          <tr>
                            <th>2</th>
                            <th>CSE </th>
                            <td>Dr. D. G. Jyothi</td>
                            <td>
                              Delay tolerant networks, Artificial Intelligence &
                              Machine Learning and Blockchain
                            </td>
                            <td>03</td>
                            <td>05</td>
                          </tr>
                          <tr>
                            <th>3</th>
                            <th>CSE </th>
                            <td>Dr. Suneetha K. R</td>
                            <td>Web mining</td>
                            <td>03</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>4</th>
                            <th>AI & ML </th>
                            <td>Dr. Kempanna M</td>
                            <td>
                              Delay tolerant networks, Artificial Intelligence &
                              Machine Learning and Blockchain
                            </td>
                            <td>03</td>
                            <td>05</td>
                          </tr>
                          <tr>
                            <th>5</th>
                            <th>AI & ML </th>
                            <td>Dr. D. G. Jyothi</td>
                            <td>Network security</td>
                            <td>03</td>
                            <td>00</td>
                          </tr>
                          <tr>
                            <th>3</th>
                            <th>CSE </th>
                            <td>Dr. Suneetha K. R</td>
                            <td>Web mining</td>
                            <td>03</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>4</th>
                            <th>AI & ML </th>
                            <td>Dr. Kempanna M</td>
                            <td>
                              Delay tolerant networks, Artificial Intelligence &
                              Machine Learning and Blockchain
                            </td>
                            <td>03</td>
                            <td>05</td>
                          </tr>
                          <tr>
                            <th>5</th>
                            <th>AI & ML </th>
                            <td>Dr. D. G. Jyothi</td>
                            <td>Network security</td>
                            <td>03</td>
                            <td>00</td>
                          </tr>
                          <tr>
                            <th>6</th>
                            <tH>ECE </tH>
                            <td>Dr M N Sreeranga Raju</td>
                            <td>Wireless Communication & Networks</td>
                            <td>03</td>
                            <td>05</td>
                          </tr>
                          <tr>
                            <th>7</th>
                            <tH>ECE </tH>
                            <td>Dr Hemath Kumar A R</td>
                            <td>
                              Sensor Networks / Mobile Networks / Wireless
                              Networks
                            </td>
                            <td>04</td>
                            <td>04</td>
                          </tr>
                          <tr>
                            <th>8</th>
                            <tH>ECE </tH>
                            <td>Dr Vijayaprakash A M</td>
                            <td>VLSI Design, Image and Video processing</td>
                            <td>04</td>
                            <td>04</td>
                          </tr>
                          <tr>
                            <th>9</th>
                            <tH>ECE </tH>
                            <td>Dr C Byrareddy</td>
                            <td>Wireless Communication & Antennas</td>
                            <td>04</td>
                            <td>04</td>
                          </tr>
                          <tr>
                            <th>10</th>
                            <tH>ECE </tH>
                            <td>Dr A B Kalpana</td>
                            <td>
                              VLSI Design and Embedded Systems, Communication
                              and Image Processing
                            </td>
                            <td>04</td>
                            <td>04</td>
                          </tr>
                          <tr>
                            <th>11</th>
                            <tH>ECE </tH>
                            <td>Dr Narendra C P</td>
                            <td>DSP and Image Processing</td>
                            <td>00</td>
                            <td>06</td>
                          </tr>
                          <tr>
                            <th>8</th>
                            <tH>EIE </tH>
                            <td>Dr.M B Meenavathi</td>
                            <td>Digital Image Processing</td>
                            <td>02</td>
                            <td>04</td>
                          </tr>
                          <tr>
                            <th>9</th>
                            <tH>EIE </tH>
                            <td>Dr. H N Suresh</td>
                            <td>Signal Processing</td>
                            <td>02</td>
                            <td>04</td>
                          </tr>
                          <tr>
                            <th>10</th>
                            <tH>EIE </tH>
                            <td>Dr. Ashwathappa P</td>
                            <td>Bio Medical Signalling</td>
                            <td>00</td>
                            <td>06</td>
                          </tr>
                          <tr>
                            <th>11</th>
                            <tH>EIE </tH>
                            <td>Dr. Sudha L K</td>
                            <td>
                              Electronics & Communication (Nanotechnology)
                            </td>
                            <td>02</td>
                            <td>04</td>
                          </tr>
                          <tr>
                            <th>12</th>
                            <tH>ETE </tH>
                            <td>Dr. S. Shanthala</td>
                            <td>Low Power VLSI</td>
                            <td>03</td>
                            <td>05</td>
                          </tr>
                          <tr>
                            <th>13</th>
                            <th>ETE </th>
                            <td>Dr. M. Rajeshwari</td>
                            <td>
                              Electronics & Communication (Nanotechnology)
                            </td>
                            <td>03</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>14</th>
                            <th>ISE </th>
                            <td>Dr.J.Prakash</td>
                            <td>Digital Image Processing</td>
                            <td>00</td>
                            <td>08</td>
                          </tr>
                          <tr>
                            <th>15</th>
                            <th>ISE </th>
                            <td>Dr.R.Nagaraja</td>
                            <td>Computer Networks</td>
                            <td>05</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>16</th>
                            <th>Mathematics </th>
                            <td>Dr. Roopa K M</td>
                            <td>
                              Fluid Mechanics, Transportation GIS, Mathematical
                              Modelling and Network Systems
                            </td>
                            <td>02</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>17</th>
                            <th>Mathematics </th>
                            <td>Dr. Dhananjaya M</td>
                            <td>Fluid Mechanics</td>
                            <td>02</td>
                            <td>00</td>
                          </tr>
                          <tr>
                            <th>18</th>
                            <th>Mathematics </th>
                            <td>Dr. K. Manjula</td>
                            <td>Combinatorial Graph Theory</td>
                            <td>01</td>
                            <td>00</td>
                          </tr>
                          <tr>
                            <th>19</th>
                            <th>EEE </th>
                            <td>Dr.Pramila</td>
                            <td>Power Systems </td>
                            <td>04</td>
                            <td>04</td>
                          </tr>
                          <tr>
                            <th>20</th>
                            <th>EEE </th>
                            <td>Dr. H B Nagesh</td>
                            <td>Power Systems</td>
                            <td>02</td>
                            <td>04</td>
                          </tr>
                          <tr>
                            <th>21</th>
                            <th>Physics </th>
                            <td>Dr. Nagaraja D</td>
                            <td>Spectroscopy, material science, shock waves</td>
                            <td>03</td>
                            <td>05</td>
                          </tr>
                        
                          <tr>
                            <th>23</th>
                            <th>Physics </th>
                            <td>Dr. Geethanjali H. S</td>
                            <td>
                              Fluorescence spectroscopy, Nano science and
                              Technology, material Science{" "}
                            </td>
                            <td>03</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>24</th>
                            <th>Physics </th>
                            <td>Dr. Raghavendra U. P</td>
                            <td>
                              Molecular Spectroscopy, Nanoscience and Material
                              science
                            </td>
                            <td>02</td>
                            <td>02</td>
                          </tr>
                          <tr>
                            <th>25</th>
                            <th>Physics </th>
                            <td>Dr. Chaluvaraju B. V</td>
                            <td>
                              Conducting Polymer Composites, Nanomaterials and
                              Materials Science
                            </td>
                            <td>02</td>
                            <td>02</td>
                          </tr>
                          <tr>
                            <th>26</th>
                            <th>Mechanical Engineering </th>
                            <td>Dr. Sreerama Reddy</td>
                            <td>Heat Transfer, Cryogenics</td>
                            <td>05</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>27</th>
                            <th>Mechanical Engineering </th>
                            <td>Dr. Ravi Kumar B N</td>
                            <td>Polymer composites</td>
                            <td>00</td>
                            <td>08</td>
                          </tr>
                          <tr>
                            <th>28</th>
                            <th>Mechanical Engineering </th>
                            <td>Dr. Ashwath</td>
                            <td>Polymer Composites</td>
                            <td>05</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>29</th>
                            <th>Mechanical Engineering </th>
                            <td>Dr. Reddappa H N</td>
                            <td>Polymer Composites</td>
                            <td>06</td>
                            <td>00</td>
                          </tr>
                          <tr>
                            <th>30</th>
                            <th>Mechanical Engineering </th>
                            <td>Dr. Satish N</td>
                            <td>Polymer Composites</td>
                            <td>01</td>
                            <td>07</td>
                          </tr>
                          <tr>
                            <th>31</th>
                            <th>Mechanical Engineering </th>
                            <td>Dr. Honnegowda</td>
                            <td>Bio Diesel</td>
                            <td>04</td>
                            <td>02</td>
                          </tr>
                          <tr>
                            <th>32</th>
                            <th>Mechanical Engineering </th>
                            <td>Dr. Manjunath M C </td>
                            <td>Bio Diesel</td>
                            <td>00</td>
                            <td>06</td>
                          </tr>
                          <tr>
                            <th>33</th>
                            <th>Mechanical Engineering </th>
                            <td>Dr. Praveen Kumar M</td>
                            <td>Heat Transfer</td>
                            <td>00</td>
                            <td>06</td>
                          </tr>
                          <tr>
                            <th>34</th>
                            <th>Mechanical Engineering </th>
                            <td>Dr. Nagaraja C Reddy</td>
                            <td>Metal Coatings</td>
                            <td>00</td>
                            <td>06</td>
                          </tr>
                          <tr>
                            <th>35</th>
                            <th>Mechanical Engineering </th>
                            <td>Dr. Babu E R</td>
                            <td>Heat Transfer, Composite Composites</td>
                            <td>01</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>36</th>
                            <th>Mechanical Engineering </th>
                            <td>Dr. Chandrashekar A</td>
                            <td>Composite Composites</td>
                            <td>00</td>
                            <td>04</td>
                          </tr>
                          <tr>
                            <th>37</th>
                            <th>Chemistry </th>
                            <td>Dr. Chandra Shekara. B. M. </td>
                            <td>
                              Heterogeneous Catalysis and Material Science.{" "}
                            </td>
                            <td>02</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>38</th>
                            <th>Chemistry </th>
                            <td>Dr. Prakruthi. H. R. </td>
                            <td>
                              Heterogeneous Catalysis, Adsorption and Material
                              Science.{" "}
                            </td>
                            <td>01</td>
                            <td>01</td>
                          </tr>
                          <tr>
                            <th>39</th>
                            <th>Chemistry </th>
                            <td>Dr. Venkatesha </td>
                            <td>
                              Heterogeneous catalysis and Nano materials.{" "}
                            </td>
                            <td>00</td>
                            <td>02</td>
                          </tr>
                          <tr>
                            <th>40</th>
                            <th>Civil Engineering </th>
                            <td>Dr. H B Balakrishna</td>
                            <td>
                              Water Resource, Remote Sensing and GIS,
                              Environmental Engineering
                            </td>
                            <td>05</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>41</th>
                            <th>Civil Engineering </th>
                            <td>Dr. H N Jagannatha Reddy</td>
                            <td>Structural Engineering</td>
                            <td>05</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>42</th>
                            <th>Civil Engineering </th>
                            <td>Dr. P M Ravindra </td>
                            <td>Structural Engineering </td>
                            <td>04</td>
                            <td>04</td>
                          </tr>
                          <tr>
                            <th>43</th>
                            <th>Civil engineering </th>
                            <td>Dr. Rudre Gowda </td>
                            <td>Environmental Engineering</td>
                            <td>00</td>
                            <td>03</td>
                          </tr>
                          <tr>
                            <th>44</th>
                            <th>Civil Engineering </th>
                            <td>Dr.B S Putte Gowda </td>
                            <td>Concrete technology Structural Engineering </td>
                            <td>01</td>
                            <td>02</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">
                        Admission process and eligibility
                      </h4>
                    </div>
                  </div>
                  <p className="justify">
                    Bangalore institute of technology has established research
                    and development (R & D) centers recognized by VTU in all the
                    thirteen departments. These R & D centers offer full time
                    and part time Ph.D programs and MSc Engineering (Research)
                    programs for eligible research scholars. The admission
                    procedure and other details obtained from the research
                    coordinators of the respective department or may visit VTU
                    website using the link – VTU Research. Fellowships for the
                    research scholars to pursue full time research program are
                    available. The eligibility criteria and other details for
                    procuring financial aid are available here (JNANA-YANNA)
                  </p>
                  <br />
                  <p className="justify">
                    Candidates seeking admission to Ph.D program are required to
                    appear for VTU Eligibility Test with a minimum 55% aggregate
                    marks in M.Tech degree under respective specialization.
                    Candidates will be assessed on the basis of entrance test
                    score and presentation on their research topic in front of
                    the VTU identified panel members. The one who qualifies
                    successfully will be allowed for admission.{" "}
                    <b>
                      For more information, please visit VTU website:
                      <a href="www.vtu.ac.in" target="_blank">
                        {" "}
                        www.vtu.ac.in{" "}
                      </a>
                    </b>
                  </p>
                </div>

                <div className="section-title with-desc text-left clearfix">
                  <div
                    className="title-header paddingtop-2"
                    style={{ borderTopColor: "red" }}
                  >
                    <h4 className="title">Admission contact information</h4>
                  </div>
                  <div className="ttm-pf-single-detail-box mb-35">
                    <ul className="ttm-pf-detailbox-list">
                      <li>
                        <i className="fa fa-user" />
                        <span>
                          {" "}
                          Principal - Bangalore Institute of Technology - K.R.
                          Road, V V Pura - Bangalore-560004
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-envelope" />
                        <span>
                          {" "}
                          Mail-ID :{" "}
                        </span>admission@bit-bangalore.edu.in{" "}
                      </li>
                      <li>
                        <i className="fas fa-phone" />
                        <span> Contact Number : </span>+91-9448512984,
                        +91-9448512954
                      </li>
                    </ul>
                  </div>
                  <div className="ttm-pf-single-detail-box mb-35">
                    <h5>
                      For Admission queries please call during working hours
                    </h5>
                    <ul className="ttm-pf-detailbox-list">
                      <li>
                        <h6>
                          <span> Working Hours :</span>
                        </h6>
                      </li>
                      <li>
                        <i className="fas fa-caret-right" />
                        <span> Monday to Fiday </span>9.00 AM - 5.00 PM /{" "}
                        <span> Saturday</span>9.00 AM - 2.00 PM{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <AdmissionSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
