import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import DepartmentVideo from "../component/DepartmentVideo";
import Footer from "../../../components/common/Footer";

export default class MATHAbout extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Physics" image="banners/department-phy.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <DepartmentAchievers department="phy" />
                  <DepartmentNumbers
                    text1="Number of students on roll"
                    num1="1320"
                    text2="Number of faculty"
                    num2="9"
                    text3="Number of faculty with Ph. D"
                    num3="5"
                    text4="Number of publications"
                    num4="112"
                    text5="Project executed worth (Rs) in lakhs"
                    num5="20"
                    text6="N/A"
                    num6="0"
                  />
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/departments/phy/about-1.jpg"
                            }
                            alt="single-img-eighteen"
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-7 justify"
                        style={{ margin: "auto" }}
                      >
                        <p>
                          The Physics department has started in the year 1979
                          with Prof. S P Basavaraju, a renowned author, as Head
                          of the department. The department is known for its
                          dedication and is a source of inspiration for all the
                          students. The department has VTU recognized R&D Centre
                          and 6 research scholars have registered for Ph.D.
                          Faculty members are actively involved in research with
                          real time applications.. Department has a research
                          project grant from Government of Karnataka under VGST
                          scheme and Dr. D. Nagaraja is the principal
                          investigator. The average retention of the staff is 17
                          years.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <DepartmentVideo department="phy" />
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="section-title with-desc text-left clearfix paddingtop-2">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Vision</h4>
                    </div>
                    <div>
                      <p>
                        “Instill the spirit of Scientific Curiosity in both the
                        teacher and the taught”
                      </p>
                    </div>
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Mission</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To involve First year students in multi varied
                          activities 
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To create learning environment by allowing students to
                          have hands on experience on engineering related
                          topics/ experiments and thereby realize the scope of
                          Physics in Engineering
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To provide a platform for both students and teachers
                          to write articles in Physics and on recent
                          developments in SCIENCE &#38; TECHNOLOGY
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="phy" />
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
