import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import DepartmentVideo from "../component/DepartmentVideo";
import Footer from "../../../components/common/Footer";

export default class CHEAbout extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Chemistry" image="banners/department-che.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <DepartmentAchievers department="chem" />
                  <DepartmentNumbers
                    text1="Number of students on roll"
                    num1="650"
                    text2="Number of faculty"
                    num2="5"
                    text3="Number of faculty with Ph. D"
                    num3="5"
                    text4="Number of Publications"
                    num4="90"
                    text5="Research projects executed (In Lakhs)"
                    num5="90"
                    text6="Ph.D Awarded"
                    num6="11"
                  />
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/departments/chem/about-1.jpg"
                            }
                            alt="single-img-eighteen"
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-7 justify"
                        style={{ margin: "auto" }}
                      >
                        <p>
                          The Department of Engineering Chemistry came into
                          existence along with the establishment of the
                          institution in the year 1979. The Department is
                          currently headed by Dr. Chandra Shekara B M and has a
                          team of highly experienced, talented and dedicated
                          faculty members all with Ph.D degree acquired from
                          reputed universities. The department is involved in
                          teaching Applied Chemistry for the first year B.E
                          students of all disciplines and plays a vital role in
                          laying the strongest foundation to their engineering
                          education.
                        </p>{" "}
                      </div>
                      <div className="col-md-12 paddingtop-2 justify">
                        <p>
                          The faculty members of the department are the authors
                          of four well accepted Applied Chemistry Textbooks.
                        </p>
                        <br />
                        <p>
                          The department has research centre recognized by
                          Visvesvaraya Technological University with
                          well-established facilities to promote both sponsored
                          & academic research and consultancy activities in
                          various fields of Applied Chemistry like heterogeneous
                          catalysis, energy, reaction kinetics, medicinal
                          chemistry and material science. Research grants to the
                          tune of more than 90 lakh rupees funded by leading
                          funding agencies have been executed.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <DepartmentVideo department="chem" />
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Vision</h4>
                    </div>
                    <p>
                      Instill the knowledge of chemistry in the areas of energy,
                      materials and environment for improving and developing
                      technologies towards sustainable development.
                    </p>
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Mission</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To impart basic chemical knowledge about various forms
                          of energy and materials.{" "}
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {" "}
                          To provide present status and future challenges in the
                          areas of energy and materials.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {" "}
                          Creating awareness on green chemistry in the areas of
                          corrosion, water and energy.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="chem" />
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
